import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import {
  toWidget,
  toWidgetEditable,
} from "@ckeditor/ckeditor5-widget/src/utils";
import Widget from "@ckeditor/ckeditor5-widget/src/widget";
import InsertGetInTouchBannerCommand from "./insertgetintouchbannercommand";

export default class GetInTouchBannerEditing extends Plugin {
  static get requires() {
    return [Widget];
  }

  init() {
    this._defineSchema();
    this._defineConverters();

    this.editor.commands.add(
      "insertGetInTouchBanner",
      new InsertGetInTouchBannerCommand(this.editor)
    );
  }

  _defineSchema() {
    const schema = this.editor.model.schema;

    schema.register("getInTouchBanner", {
      isObject: true,
      allowWhere: "$block",
    });

    schema.register("getInTouchBannerContent", {
      isLimit: true,
      allowIn: "getInTouchBanner",
    });

    schema.register("getInTouchBannerTitle", {
      isLimit: true,
      allowIn: "getInTouchBannerContent",
      allowContentOf: "$block",
    });

    schema.register("getInTouchBannerDescription", {
      isLimit: true,
      allowIn: "getInTouchBannerContent",
      allowContentOf: "$block",
    });

    schema.register("getInTouchBannerLink", {
      isLimit: true,
      allowIn: "getInTouchBanner",
      allowContentOf: "$block",
      allowAttributes: ["href", "target"]
    });

    schema.register("getInTouchBannerHideContent", {
      isLimit: true,
      allowIn: "getInTouchBannerContent",
      allowContentOf: "$block"
    });
  }

  _defineConverters() {
    const conversion = this.editor.conversion;

    // Banner container converters
    conversion.for("upcast").elementToElement({
      model: "getInTouchBanner",
      view: {
        name: "section",
        classes: "get-in-touch-banner",
      },
    });
    conversion.for("dataDowncast").elementToElement({
      model: "getInTouchBanner",
      view: {
        name: "section",
        classes: "get-in-touch-banner",
      },
    });
    conversion.for("editingDowncast").elementToElement({
      model: "getInTouchBanner",
      view: (modelElement, viewWriter) => {
        const section = viewWriter.createContainerElement("section", {
          class: "get-in-touch-banner",
        });
        return toWidget(section, viewWriter, {
          label: "get in touch banner widget",
        });
      },
    });

    // Content wrapper converters
    conversion.for("upcast").elementToElement({
      model: "getInTouchBannerContent",
      view: {
        name: "div",
        classes: "get-in-touch-banner-content",
      },
    });
    conversion.for("dataDowncast").elementToElement({
      model: "getInTouchBannerContent",
      view: {
        name: "div",
        classes: "get-in-touch-banner-content",
      },
    });
    conversion.for("editingDowncast").elementToElement({
      model: "getInTouchBannerContent",
      view: (modelElement, viewWriter) => {
        const div = viewWriter.createEditableElement("div", {
          class: "get-in-touch-banner-content",
        });
        return toWidgetEditable(div, viewWriter);
      },
    });

    // Title converters
    conversion.for("upcast").elementToElement({
      model: "getInTouchBannerTitle",
      view: {
        name: "div",
        classes: "get-in-touch-banner-title",
      },
    });
    conversion.for("dataDowncast").elementToElement({
      model: "getInTouchBannerTitle",
      view: {
        name: "div",
        classes: "get-in-touch-banner-title",
      },
    });
    conversion.for("editingDowncast").elementToElement({
      model: "getInTouchBannerTitle",
      view: (modelElement, viewWriter) => {
        const div = viewWriter.createEditableElement("div", {
          class: "get-in-touch-banner-title",
        });
        return toWidgetEditable(div, viewWriter);
      },
    });

    // Description converters
    conversion.for("upcast").elementToElement({
      model: "getInTouchBannerDescription",
      view: {
        name: "span",
        classes: "get-in-touch-banner-description",
      },
    });
    conversion.for("dataDowncast").elementToElement({
      model: "getInTouchBannerDescription",
      view: {
        name: "span",
        classes: "get-in-touch-banner-description",
      },
    });
    conversion.for("editingDowncast").elementToElement({
      model: "getInTouchBannerDescription",
      view: (modelElement, viewWriter) => {
        const span = viewWriter.createEditableElement("span", {
          class: "get-in-touch-banner-description",
        });
        return toWidgetEditable(span, viewWriter);
      },
    });

    // Link converters
    conversion.for("upcast").elementToElement({
      model: "getInTouchBannerLink",
      view: {
        name: "a",
        classes: "get-in-touch-banner-link"
      }
    });

    conversion.for("dataDowncast").elementToElement({
      model: "getInTouchBannerLink",
      view: {
        name: "a",
        classes: "get-in-touch-banner-link"
      }
    });

    conversion.for("editingDowncast").elementToElement({
      model: "getInTouchBannerLink",
      view: (modelElement, viewWriter) => {
        const link = viewWriter.createEditableElement("a", {
          class: "get-in-touch-banner-link"
        });
        return toWidgetEditable(link, viewWriter);
      }
    });

    // Hidden content converters
    conversion.for("upcast").elementToElement({
      model: "getInTouchBannerHideContent",
      view: {
        name: "p",
        classes: "get-in-touch-banner-hide-content"
      }
    });

    conversion.for("dataDowncast").elementToElement({
      model: "getInTouchBannerHideContent",
      view: {
        name: "p",
        classes: "get-in-touch-banner-hide-content"
      }
    });


    conversion.for("editingDowncast").elementToElement({
      model: "getInTouchBannerHideContent",
      view: (modelElement, viewWriter) => {
        const div = viewWriter.createEditableElement("p", {
          class: "get-in-touch-banner-hide-content",
        });
        return toWidgetEditable(div, viewWriter);
      },
    });
  }
}
