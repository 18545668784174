import Plugin from "@ckeditor/ckeditor5-core/src/plugin";

// ADDED 2 imports
import {
  toWidget,
  toWidgetEditable,
} from "@ckeditor/ckeditor5-widget/src/utils";
import Widget from "@ckeditor/ckeditor5-widget/src/widget";
import InsertBannerCommand from "./insertbannercommand";

export default class BannerEditing extends Plugin {
  static get requires() {
    return [Widget];
  }

  init() {
    this._defineSchema();
    this._defineConverters();

    this.editor.commands.add(
      "insertBanner",
      new InsertBannerCommand(this.editor)
    );
  }

  _defineSchema() {
    const schema = this.editor.model.schema;

    schema.register("banner", {
      isObject: true,
      allowWhere: "$block",
      allowAttributes: ["class"],
    });

    schema.register("bannerContent", {
      isLimit: true,
      allowIn: "banner",
    });

    schema.register("bannerTitle", {
      // Cannot be split or left by the caret.
      isLimit: true,

      allowIn: "bannerContent",

      // Allow content which is allowed in blocks (i.e. text with attributes).
      allowContentOf: "$block",
      allowAttributes: ["class"],
    });

    schema.register("bannerDescription", {
      // Cannot be split or left by the caret.
      isLimit: true,

      allowIn: "bannerContent",

      // Allow content which is allowed in the root (e.g. paragraphs).
      allowContentOf: "$block",
    });

    schema.register("bannerButton", {
      // Cannot be split or left by the caret.
      isLimit: true,

      allowIn: "banner",

      // Allow content which is allowed in the root (e.g. paragraphs).
      allowContentOf: "$block",
    });

    schema.register("bannerHideContent", {
      // Cannot be split or left by the caret.
      isLimit: true,

      allowIn: "banner",

      // Allow content which is allowed in the root (e.g. paragraphs).
      allowContentOf: "$block",
    });

    schema.addChildCheck((context, childDefinition) => {
      if (
        context.endsWith("bannerDescription") &&
        childDefinition.name == "banner"
      ) {
        return false;
      }
    });

    schema.addChildCheck((context, childDefinition) => {
      if (context.endsWith("bannerTitle") && childDefinition.name == "banner") {
        return false;
      }
    });
    schema.addChildCheck((context, childDefinition) => {
      if (
        context.endsWith("bannerbutton") &&
        childDefinition.name == "banner"
      ) {
        return false;
      }
    });
    schema.addChildCheck((context, childDefinition) => {
      if (
        context.endsWith("bannerHideContent") &&
        childDefinition.name == "banner"
      ) {
        return false;
      }
    });
  }

  _defineConverters() {
    const conversion = this.editor.conversion;

    // <simpleBox> converters
    conversion.for("upcast").elementToElement({
      model: "banner",
      view: {
        name: "section",
        classes: "banner",
      },
    });
    conversion.for("dataDowncast").elementToElement({
      model: "banner",
      view: {
        name: "section",
        classes: "banner",
      },
    });
    conversion.for("editingDowncast").elementToElement({
      model: "banner",
      view: (modelElement, viewWriter) => {
        const section = viewWriter.createContainerElement("section", {
          class: "banner",
        });

        return toWidget(section, viewWriter, { label: "banner widget" });
      },
    });

    // Content wrapper converters
    conversion.for("upcast").elementToElement({
      model: "bannerContent",
      view: {
        name: "div",
        classes: "banner-content",
      },
    });
    conversion.for("dataDowncast").elementToElement({
      model: "bannerContent",
      view: {
        name: "div",
        classes: "banner-content",
      },
    });
    conversion.for("editingDowncast").elementToElement({
      model: "bannerContent",
      view: (modelElement, viewWriter) => {
        const div = viewWriter.createEditableElement("div", {
          class: "banner-content",
        });
        return toWidgetEditable(div, viewWriter);
      },
    });

    // <simpleBoxTitle> converters
    conversion.for("upcast").elementToElement({
      model: "bannerTitle",
      view: {
        name: "h3",
        classes: "banner-title",
      },
    });
    conversion.for("dataDowncast").elementToElement({
      model: "bannerTitle",
      view: {
        name: "h3",
        classes: "banner-title",
      },
    });
    conversion.for("editingDowncast").elementToElement({
      model: "bannerTitle",
      view: (modelElement, viewWriter) => {
        // Note: You use a more specialized createEditableElement() method here.
        const h3 = viewWriter.createEditableElement("h3", {
          class: "banner-title",
        });

        return toWidgetEditable(h3, viewWriter);
      },
    });

    // <simpleBoxDescription> converters
    conversion.for("upcast").elementToElement({
      model: "bannerDescription",
      view: {
        name: "div",
        classes: "banner-description",
      },
    });
    conversion.for("dataDowncast").elementToElement({
      model: "bannerDescription",
      view: {
        name: "div",
        classes: "banner-description",
      },
    });
    conversion.for("editingDowncast").elementToElement({
      model: "bannerDescription",
      view: (modelElement, viewWriter) => {
        // Note: You use a more specialized createEditableElement() method here.
        const div = viewWriter.createEditableElement("div", {
          class: "banner-description",
        });

        return toWidgetEditable(div, viewWriter);
      },
    });

    conversion.for("upcast").elementToElement({
      model: "bannerButton",
      view: {
        name: "div",
        classes: "banner-button",
      },
    });
    conversion.for("dataDowncast").elementToElement({
      model: "bannerButton",
      view: {
        name: "div",
        classes: "banner-button",
      },
    });
    conversion.for("editingDowncast").elementToElement({
      model: "bannerButton",
      view: (modelElement, viewWriter) => {
        // Note: You use a more specialized createEditableElement() method here.
        const div = viewWriter.createEditableElement("div", {
          class: "banner-button",
        });
        return toWidgetEditable(div, viewWriter);
      },
    });

    conversion.for("upcast").elementToElement({
      model: "bannerHideContent",
      view: {
        name: "div",
        classes: "banner-hide-content",
      },
    });
    conversion.for("dataDowncast").elementToElement({
      model: "bannerHideContent",
      view: {
        name: "div",
        classes: "banner-hide-content",
      },
    });
    conversion.for("editingDowncast").elementToElement({
      model: "bannerHideContent",
      view: (modelElement, viewWriter) => {
        // Note: You use a more specialized createEditableElement() method here.
        const div = viewWriter.createEditableElement("div", {
          class: "banner-hide-content",
        });
        return toWidgetEditable(div, viewWriter);
      },
    });
  }
}
